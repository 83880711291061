import styled from "@emotion/styled";
import { Cubit } from "blac-next";
import React, { FC, useEffect } from "react";
import envVariables from "src/lib/envVariables";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import { userPreferences, userState } from "src/state/state";
import { useBlocNext } from "@blac/react";
import { CmsImageRootObject } from "src/types/sanitySchema";
import ResponsiveImage from "../ResponsiveImage/ResponsiveImage";
import { ProfileAttributesKeys } from "src/state/UserCubit/UserCubit";
import MedicalInsuranceFormBloc from "../MedicalInsuranceForm/MedicalInsuranceFormBloc";

const InsuranceCard = styled.div`
  position: relative;
  width: 95%;
  margin-top: 1rem;

  .responsive-image {
    border-radius: 1rem;
    border: 6px solid #fff;
    background: #fff;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 2;
  }

  :after {
    content: "";
    position: absolute;
    inset: 0;
    display: block;
    z-index: 1;

    border-radius: 1rem;
    background: linear-gradient(
      252deg,
      rgba(255, 209, 153, 0.75) -21.67%,
      rgba(250, 208, 232, 0.75) 37.45%,
      rgba(166, 198, 255, 0.75) 90.57%
    );
    transform: rotate(5deg) translate(7%, -2%);
  }
`;

class InsuranceCardBloc extends Cubit<{ image?: CmsImageRootObject }> {
  questionnaireCubit?: QuestionnaireCubit;

  constructor() {
    super({});
  }

  loadImage = async (healthPlanName?: string) => {
    const url = new URL(
      `${envVariables.CMS_API_BASE_URL}/api/content/insurance/card-image`
    );

    const { profileAttributes } = userState.state;

    const insurance =
      healthPlanName ??
      profileAttributes?.[ProfileAttributesKeys.partnerPayersCurrent]?.partner
        .name;

    const employer =
      profileAttributes?.[ProfileAttributesKeys.partnerEmployersCurrent]
        ?.partner.name;

    url.searchParams.append("insurance", insurance ?? "");
    url.searchParams.append("employer", employer ?? "");
    url.searchParams.append("language", userPreferences.language ?? "");

    const request = await fetch(url);

    const json = (await request.json()) as {
      image: null | CmsImageRootObject;
    };

    if (json.image) {
      this.emit({
        image: json.image
      });
    }
  };
}

const ExampleInsuranceCardInApp: FC = () => {
  const [stateInsuranceCard, { loadImage }] = useBlocNext(InsuranceCardBloc);

  const [{ temporarySelectedHealthPlanName }] = useBlocNext(
    MedicalInsuranceFormBloc
  );

  useEffect(() => {
    void loadImage(temporarySelectedHealthPlanName);
  }, []);

  if (stateInsuranceCard.image) {
    return (
      <InsuranceCard>
        <ResponsiveImage image={stateInsuranceCard.image} />
      </InsuranceCard>
    );
  }

  return null;
};

export default ExampleInsuranceCardInApp;
